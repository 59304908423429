<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
             <b-card-code >
                <validation-observer ref="simpleRules">
                    <form @submit.prevent="submitForm">
                      <b-row>
                        <b-col md="4">
                          <b-form-group>
                            <label>Select Employee</label>
                            <v-select
                            v-model="employee"
                            label="name"
                            placeholder="None"
                            :options="employeeOptions"
                            />
                          </b-form-group>
                        </b-col>
                        <!-- <b-col md="4">
                          <b-form-group>
                            <label>Branch</label>
                            <v-select
                            @input="getEmployee()"
                            v-model="branchname"
                            placeholder="None"
                            label="name"
                            :options="branchOptions"
                            />
                          </b-form-group>
                        </b-col> -->
                        <b-col md="4">
                          <b-form-group>
                            <label>Month</label>
                            <v-select
                            v-model="month"
                            label="name"
                            placeholder="None"
                            :options="['All',1,2,3,4,5,6,7,8,9,10,11,12]"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group>
                            <label>Year</label>
                            <v-select
                            v-model="year"
                            label="name"
                            placeholder="None"
                            :options="[2000,2001,2002,2003,2004,2005,2006,2007,2008,2009,2010,2011,2012,2013,2014,2015,2016,2017,2018,2019,2020,2021,2022,2023,2024,2025,2026,2027,2028,2029,2030,2031,2032,2033,2034,2035,2036,2037,2038,2039,2040,2041,2042,2043,2044,2045,2046,2047,2048,2049,2050,2051,2052]"
                            />
                          </b-form-group>
                        </b-col>


                      </b-row>
                      <div class="d-flex justify-content-end">
                        <b-button  variant="primary" class="ml-2" @click="searchData">
                          <feather-icon icon="SearchIcon" class="mr-50" />
                          <span class="align-middle">Search</span>
                        </b-button>
                      </div>
                    </form>
                </validation-observer>
            </b-card-code>
              <report-table
                :data="data"
                :columns="fields"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios'
import BCardCode from '@core/components/b-card-code'
import flatPickr from 'vue-flatpickr-component'
import ReportTable from '../../../components/ReportTable.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import moment from 'moment'
import vSelect from 'vue-select'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy
} from 'bootstrap-vue'
const baseApi = process.env.VUE_APP_APIENDPOINT
export default {
  components: {
    vSelect,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    ReportTable
  },
  data () {
    return {
      branchOptions:[{name:'Channel Partner', id:'chanelpartner'}, {name:'Franchise', id:'franchise'}, {name:'Admin', id:'admin'}],
      branchname:'',
      employeeOptions: [],
      employee:'',
      year:'',
      month: '',
      data: '',
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem('accessToken'),
      fields: [
        { field: 'id', label: 'S.R. No', hidden: true, },
        { field: 'employee', url:'getUsers', type:'dropdown', responseValue:'name', label: 'Employee Name', },
        { field: 'machinecode', label: 'Machine Code', },
        { field: '1', label: '1', },
        { field: '2', label: '2', },
        { field: '3', label: '3', },
        { field: '4', label: '4', },
        { field: '5', label: '5', },
        { field: '6', label: '6', },
        { field: '7', label: '7', },
        { field: '8', label: '8', },
        { field: '9', label: '9', },
        { field: '10', label: '10', },
        { field: '11', label: '11', },
        { field: '12', label: '12', },
        { field: '13', label: '13', },
        { field: '14', label: '14', },
        { field: '15', label: '15', },
        { field: '16', label: '16', },
        { field: '17', label: '17', },
        { field: '18', label: '18', },
        { field: '19', label: '19', },
        { field: '20', label: '20', },
        { field: '21', label: '21', },
        { field: '22', label: '22', },
        { field: '23', label: '23', },
        { field: '24', label: '24', },
        { field: '25', label: '25', },
        { field: '26', label: '26', },
        { field: '27', label: '27', },
        { field: '28', label: '28', },
        { field: '29', label: '29', },
        { field: '30', label: '30', },
        { field: 'total', label: 'Total Hours',  }
      ],
    }
  },
  mounted(){
    this.getEmployee()
  },
  methods: {
    async getEmployee (id) {
      this.employeeOptions = []
      // let url = `getPositionWiseEmployee/${id}`
      // if(id == 'all'){
      //   url='getEmployee'
      // }
        await axios({
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.accessToken}`
          },
          url: `${this.baseApi}/getEmployee`
        })
          .then((response) => {
            this.employeeOptions = response.data.data
            this.employeeOptions.unshift({name:'All', id:'all'})
          })
      },
    async searchData (tableData) {
      const data = {
        branch:this.branchname.id,
        year:this.year,
        month:this.month
      }
      await axios({
        method:'POST',
        headers:{
          'Content-Type':'application/json',
          'Authorization': `Bearer ${this.accessToken}`
        },
        data:JSON.stringify(data),
        url:`${this.baseApi}/attendancereport`
      })
        .then((response) => {
          this.data = response.data.data
        })
    }
    // async getEmployee(){
    //     this.employee=[]
    //     this.employeeOptions=[]
    //      if(this.branchname=="Channel Partner"){
    //      await axios({
    //     method: 'GET',
    //         headers: {
    //           'Content-Type': 'application/json',
    //           'Authorization': `Bearer ${this.accessToken}`
    //         },
    //         url: `${this.baseApi}/getChannelpartner`
    //       })
    //       .then((response)=>{

    //         this.employeeOptions=response.data.data;
    //       })
    //   }
    //   else if(this.branchname=="Franchise"){
    //     await axios({
    //     method: 'GET',
    //         headers: {
    //           'Content-Type': 'application/json',
    //           'Authorization': `Bearer ${this.accessToken}`
    //         },
    //         url: `${this.baseApi}/getFranchise`
    //       })
    //       .then((response)=>{
    //         this.employeeOptions=response.data.data;
    //       })
    //   }
    //   else if(this.branchname=="Admin"){
    //      await axios({
    //     method: 'GET',
    //         headers: {
    //           'Content-Type': 'application/json',
    //           'Authorization': `Bearer ${this.accessToken}`
    //         },
    //         url: `${this.baseApi}/team`
    //       })
    //       .then((response)=>{
    //         this.employeeOptions=response.data.data
    //       })
    //   }
    // }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
